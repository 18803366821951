import ApplicationController from "./application_controller";

export default class extends ApplicationController {

  scroll() {
    if (this.element.scrollLeft > 0) {
      this.element.classList.add("is-scrolled");
    } else {
      this.element.classList.remove("is-scrolled");
    }
  }
}