import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["toggleable", "toggleTrigger"];

  connect() {
    this.toggleDisplay();
  }

  toggleDisplay() {
    const shouldShow = this._shouldShow();

    if (shouldShow) {
      this.toggleableTarget.classList.remove("vanish");
    } else {
      this.toggleableTarget.classList.add("vanish");
    }
  }

  _shouldShow() {
    // If the toggle trigger is a checkbox
    // then show the toggleable if the checkbox is unchecked
    if (this.toggleTriggerTarget.type === "checkbox") {
      return !this.toggleTriggerTarget.checked;
    }
  }
}
