import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  removeFilterPill(event) {
    this.stimulate("AssignmentReflex#remove_filter_pill");
    this.isReflexComplete = false; // Flag to track Reflex completion
  }

  afterReflex(element, reflex) {
    if (reflex === "AssignmentReflex#remove_filter_pill") {
      this.isReflexComplete = true;

      document
        .querySelector("#index-filters-container #search-self-submit-form")
        .requestSubmit();
    }
  }

  saveFilters(e) {
    window.onbeforeunload = null; // Remove any unload warnings
    if ( e.keyCode == 13) { return false; }

    e.preventDefault();
    const form = document.querySelector(e.target.dataset.formId);
    const moduleType = e.target.dataset.moduleType;
    const csrfToken = document.querySelector('meta[name="csrf-token"]').content;

    if (form) {
      fetch(`/admins/assignments/save_filters?module_type=${moduleType}`, {
        method: 'POST',
        headers: {
          'X-CSRF-Token': csrfToken
        },
        body: new FormData(form)
      })
    }

  }
}
