import ApplicationController from "./application_controller";

export default class extends ApplicationController {

  initialize() {
    const mainDetailsBodyContainer = document.getElementById('main-details-body-container');
    if (mainDetailsBodyContainer) {
      const activeTab = mainDetailsBodyContainer.querySelector(".tab[data-is-active='true']").dataset.btnTypeValue;
      mainDetailsBodyContainer.querySelector(`#group-tab-${activeTab}`).classList.add('is-active');
    }
  }

  renderSpinnerLoaderThenEdit() {
    this.stimulate("ApplicationReflex#render_spin_loader", this.element, `#main-details-list-container`, "main-details-spinner-loader", "main-details-spinner-loader").then(() => {  
      // setTimeout(() => {
        this.stimulate("ResourceReflex#render_edit_page", this.element);
      // }, 1000);
    });
  }
}