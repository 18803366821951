import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = ["btn", "columnsBtn", "rowsBtn", "toggleBtn", "tasksContainer"];
  static values = {
    todoListUuid: String,
    moduleType: String
  }

  switchTasksPanelView(e) {
    this.btnTargets.forEach(btn => {
      btn.classList.remove("is-active");
    });
    e.currentTarget.classList.add("is-active");
    if (e.currentTarget.dataset.btnType == "columns") {
      this.stimulate("TaskReflex#render_task_rows", this.todoListUuidValue, this.moduleTypeValue, false)
      this.element.classList.remove("is-rows");
    } else {
      this.stimulate("TaskReflex#render_task_rows", this.todoListUuidValue, this.moduleTypeValue, true).then(() => {
        this.element.classList.add("is-rows");
      });
    }
  }
  switchTaskStatusView(e) {
    const clickedBtn = e.currentTarget;
    const selectedTasksContainer = document.querySelector(`.tasks-col-component--container[data-view='${clickedBtn.dataset.view}']`);
    document?.querySelector("#no-tasks-resource-message")?.remove();    
    this.toggleBtnTargets.forEach(toggleBtn => {
      toggleBtn.classList.remove("is-selected");
    });
    clickedBtn.classList.add("is-selected");
    this.tasksContainerTargets.forEach(tasksContainer => {
      tasksContainer.classList.add("vanish");
    });
    selectedTasksContainer.classList.remove("vanish");
    if (selectedTasksContainer.childElementCount < 1) this.stimulate("TaskReflex#render_no_tasks_message", selectedTasksContainer.parentElement.id);
  }
}