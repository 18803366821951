import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["numberContainer"];
  static values = { listItemClass: String };

  connect() {
    this.numberListItems();
  }

  numberListItems() {
    let visibleItems = Array.from(document.querySelectorAll('.' + this.listItemClassValue)).filter(
      (row) => !row.classList.contains("vanish")
    );

    visibleItems.forEach((element, index) => {
      const number = index + 1;
      const numberContainer = element.querySelector(
        "[data-nested-attributes-numbering-target='numberContainer']"
      );
      numberContainer.textContent = `${number}`;
    });
  }
}
