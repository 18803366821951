import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = ["tab"]
  
  
  setTabColor(e) {
    this.updateMainDetailsBackground(e.currentTarget);
  }
  

  // Fetch tab colors from the backend (e.g., stored in `tab_color` JSON)
  getTabColorsFromDB() {
    const tabColors = document.getElementById("main-details-container--card--body--tabs-container--component").dataset.tabColors;
    return tabColors ? JSON.parse(tabColors) : {};
  }

  loadTabColor(tabSelector, color) {
    const mainDetailsListContainer = document.getElementById("main-details-list-container");
    const tabGroup = mainDetailsListContainer.querySelector(`#group-${tabSelector}`);

    this.element.querySelector(`.${tabSelector}`).style.setProperty("--tab-background-color", color);

    if (tabGroup.classList.contains("is-active")) {
      mainDetailsListContainer.style.setProperty("--main-details-background", color);
    }
  }

  updateTabColor(e) {
    const color = getComputedStyle(e.currentTarget).getPropertyValue("--color-picker-color")
    const tabName = e.currentTarget.dataset.tabName;
    const humanizedTabName = e.currentTarget.dataset.humanizedTabName;
    const tabGroup = document.querySelector(`#group-${tabName}`);
    const tab = this.element.querySelector(`.${tabName}`)
    const existingColor =  getComputedStyle(tab).getPropertyValue("--tab-background-color")
    const colorPickers = document.querySelectorAll(".color-picker--container--wrapper");
    if (existingColor === color) {
      this.renderToast("main-details-container--card--body--tabs-container--component", `The previously selected color is the same as the new color for ${humanizedTabName}`, "danger", false, null, 5000);
    }
    const form = document.getElementById("color-picker-form");
    const colorField = document.getElementById("tab-color-field");
    const tabNameField = document.getElementById("tab-name")
    
    if (colorField && form && tabNameField) {
      colorPickers.forEach(colorPicker => {
        colorPicker.classList.remove("is-active");
      });
      e.currentTarget.classList.add("is-active");
      colorField.value = color;
      tabNameField.value = tabName

      // Submit the form via fetch
      fetch(form.action, {
        method: form.method,
        body: new FormData(form),
        headers: {
          'Accept': 'application/json', // Ensure the server responds with JSON
        },
      })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json(); // Parse JSON response
      })
      .then(data => {
        this.element.querySelector(`.${tabName}`).style.setProperty("--tab-background-color", color);
        if (tabGroup.classList.contains("is-active")) {
          tabGroup.style.setProperty("--main-details-background", color);
        }
        // Show success toast message
        this.renderToast("main-details-container--card--body--tabs-container--component", `Color for ${humanizedTabName} updated successfully`, "success", false, null, 5000);
      })
      .catch(error => {
        console.error('There was a problem with the fetch operation:', error);
      });
    }
  }

  updateMainDetailsBackground(element, dbColor = null) {
    let color = dbColor;
    if (!color) {
      color = getComputedStyle(element).getPropertyValue("--tab-background-color");
    }
    const mainDetailsListContainer = document.getElementById("main-details-list-container");
    mainDetailsListContainer.style.setProperty("--main-details-background", color);
  }

  connect(){
    super.connect();
    document.addEventListener('setTabColor', (e) => {
      this.updateMainDetailsBackground(e.target);
    });
  }
}
